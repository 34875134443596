import { QuillModules } from "ngx-quill";

export const EditorConfig : QuillModules = {
  toolbar: [
    [{ header: [false, 1, 2, 3, 4] }],
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],
    ["bold", "italic", "underline", "strike"],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link', 'image', 'video'],
    ['blockquote', 'code-block'],
  ],
};
